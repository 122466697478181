import { reactive, createApp, defineAsyncComponent, computed, ref } from 'vue'
import { registerBaseContext } from 'public/src/pages/common/common_inject'
import { getPageAbt, getLanguage } from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/utils/fetcher'
import { getStyleConfig } from './getStyleConfig'
import { analysis17414, analysis17410 } from './buryPointEvent'
import { getFreeShippingChange } from './freeShipping'
import { usePopoverPromise } from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/hooks/promise'
// import { getFreeShipping } from 'public/src/pages/common/cart_module/utils/index.js'
import { template } from '@shein/common-function'
import { getProductConfig } from 'public/src/pages/common/add-on-dialog/utils/productConfig.js'
const { IS_SUGGESTED } = gbCommonInfo

const components = {
  CommonHeader: defineAsyncComponent(() => import('../layout/common/CommonHeader.vue')),
  CommonFooter: defineAsyncComponent(() => import('../layout/common/CommonFooter.vue')),
  CommonHeaderRightWidget: defineAsyncComponent(() => import('../layout/common/CommonHeaderRightWidget.vue')),
  CommonProgress: defineAsyncComponent(() => import('public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/components/ProgressBar.vue')),
  ReducePrice: defineAsyncComponent(() => import('../layout/pendant/ReducePrice.vue')),
  DiscountPrice: defineAsyncComponent(() => import('../layout/pendant/DiscountPrice.vue')),
  FreeShippingProgress: defineAsyncComponent(() => import('../layout/free-shipping/FreeShippingProgress.vue')),
  AccessorySubHeader: defineAsyncComponent(() => import('../layout/accessory/SubHeader.vue')),
  AccessoryRetain: defineAsyncComponent(() => import('../layout/accessory/RetainDialog.vue')),
  EveryFullProgress: defineAsyncComponent(() => import('../layout/pendant/EveryFullProgress.vue')),
  EarnedBenefits: defineAsyncComponent(() => import('../layout/pendant/EarnedBenefits.vue')),
  ReturnCouponHead: defineAsyncComponent(() => import('../layout/returnCoupon/HeadIndex.vue')),
}

export function useAddOnDialog() {
  const state = reactive({
    visible: false,
    language: {},
    addCartNum: 0,
    couponChange: 0,
    shippingFullTimes: 0,
    freeShippingProgressFull: false,
    // simpleFreeShipping: false,
    popoverOptions: {
      isShow: false,
      content: '',
      duration: 4000,
    },
    config: {
      isClickToDetail: false,
      isCloseIncentivePoint: false,
      continueBtnUseLangKey: '',
      titleUseLangKey: '',
      isBuyNow: false,
      casualCheckoutNo: '',
    },
    isSuggested: IS_SUGGESTED,
    options: {},
    /*     storeInfo: {
      // id: 2633567088,
      // name: "QIAN-XUn General"
    }, // optional, with id and name */
  })
  const isUpdate = ref(false)
  const toggle = (show) => {
    state.visible = show
  }

  const open = async (options = {}) => {
    const { addOnType } = options
    if (!addOnType) return console.error('addOnType is required')
    const styleConfig = getStyleConfig(addOnType)
    const [abtInfo, language, productConf] = await Promise.all([getPageAbt(), getLanguage(), getProductConfig({ code: 'addonV2ListExposeCode' })])

    if (state.couponChange != 1) {
      if (addOnType == 'freeShipping') {
        const { isChange, isFreightStageChange } = getFreeShippingChange(options, state)
        if (isUpdate.value && (isChange || isFreightStageChange)) {
          state.couponChange = 1
          state.shippingFullTimes++
        }
        if (isUpdate.value && options.diffPrice?.amount == 0 && !state.freeShippingProgressFull) {
          state.couponChange = 1
          state.shippingFullTimes++
          state.freeShippingProgressFull = true
        }
      } else {
        state.couponChange = options.range > state.range ? 1 : 0
      }
    }

    Object.assign(state, {
      abtInfo,
      language: language?.language || {},
      styleConfig,
      itemConfig: productConf?.itemConfig,
      recommendQuery: productConf?.recommendQuery,
      headerComponent,
      subHeaderComponent,
      footerComponent,
      headerRightComponent,
      progressComponent,
      retainDialogComponent,
      footerTopComponent,
    }, options, { options })
    toggle(true)
    isUpdate.value = true
  }

  const close = (payload) => {
    state.onClose && state.onClose(payload)
    // 关闭的埋点事件
    const fn = payload?.action == 'viewCart' ? analysis17410 : analysis17414
    fn({
      add_cart_number: state.addCartNum,
      coupon_change: state.couponChange,
      state: state?.saInfo?.state,
      is_satisfied: state.isSatisfied,
      ...(state.addOnType === 'freeShipping' ? { shipping_full_times: state.shippingFullTimes } : {})
    })


    toggle(false)
    // reset一些数据
    state.addCartNum = 0
    state.couponChange = 0
    state.shippingFullTimes = 0
    state.freeShippingProgressFull = false
    state.satisfyFreeShipping = false
    state.headerComponentName = ''
    state.footerComponentName = ''
    state.headerRightComponentName = ''
    state.progressComponentName = ''
    state.subHeaderComponentName = ''
    state.retainDialogComponentName = ''
    state.footerTopComponentName = ''
    delete state.range
    isUpdate.value = false
  }

  let _popoverTimer = null
  const showPopover = (content = '', duration = 4000) => {
    if (_popoverTimer) return
    if (!content) return
    state.popoverOptions.content = content
    state.popoverOptions.duration = duration
    state.popoverOptions.isShow = true
    _popoverTimer = setTimeout(() => {
      state.popoverOptions.isShow = false
      clearTimeout(_popoverTimer)
      _popoverTimer = null
    }, duration)
  }

  // 获取简单免邮接口信息
  // watch(() => state.visible, (visible) => {
  //   if (visible) {
  //     getSimpleFreeShipping()
  //   }
  // }, { immediate: true })
  // const getSimpleFreeShipping = () => {
  //   // 非免邮凑单才调用
  //   if (state.addOnType === 'freeShipping') return
  //   getFreeShipping().then(({ showFreeShipping }) => {
  //     state.simpleFreeShipping = showFreeShipping
  //   })
  // }

  const updateCart = (payload) => {
    usePopoverPromise(state).init()
    // getSimpleFreeShipping()
    state.addCartNum += 1
    if (typeof state?.onUpdateCart === 'function') {
      state.onUpdateCart(payload)
    }
  }

  const updateOpts = (options = {}) => {
    // 特殊场景：免邮凑单弹窗，在 checkout_shipping_add 场景下，切换活动，只更新进度条和提示语
    if (
      state.saInfo.activity_from === 'checkout_shipping_add' && state.addOnType === 'freeShipping'
    ) {
      if (options.isActivityEnd) {
        close(false)
        return
      }
      if (options.isActivityStop) {
        Object.assign(state, {
          ...options,
          tip: template(state.shippingMethodName, state.language.SHEIN_KEY_PC_30309),
          progressRate: 100,
          callShippingFreeMallCount: options.callShippingFreeMallCount,
        })
        return
      }
    }
    Object.assign(state, options)
  }

  // 弹窗头部组件
  const headerComponent = computed(() => {
    return components[state.headerComponentName] || components.CommonHeader
  })
  // 弹窗底部组件
  const footerComponent = computed(() => {
    return components[state.footerComponentName] || components.CommonFooter
  })
  // 弹窗头部右侧小组件
  const headerRightComponent = computed(() => {
    return components[state.headerRightComponentName] || components.CommonHeaderRightWidget
  })
  // 弹窗底部进度条组件
  const progressComponent = computed(() => {
    return components[state.progressComponentName] || components.CommonProgress
  })
  // 弹窗副头部组件
  const subHeaderComponent = computed(() => {
    return components[state.subHeaderComponentName] || null
  })

  const retainDialogComponent = computed(() => {
    return components[state.retainDialogComponentName] || null
  })

  // 弹窗底部上侧组件
  const footerTopComponent = computed(() => {
    return components[state.footerTopComponentName] || null
  })

  return {
    state,
    open,
    close,
    toggle,
    showPopover,
    updateCart,
    updateOpts,
  }
}

export function mountComponent(RootComponent) {
  const app = createApp(RootComponent)
  registerBaseContext(app)
  const root = document.createElement('div')
  document.body.appendChild(root)
  
  return {
    instance: app.mount(root),
    unmount: () => {
      app.unmount()
      root.remove()
    }
  }
}

/**
 * 延迟执行
 * @param {number} duration 延迟时间，单位毫秒
 * @returns 
 */
export function sleep(duration) {
  return new Promise((resolve) => {
    let timer = setTimeout(() => {
      clearTimeout(timer)
      timer = null
      resolve()
    }, duration)
  })
}
