<template>
  <s-dialog
    v-model:visible="isShow"
    class="cart-promotion__drawer"
    :show-close="false"
    type="W996"
    @closed="handleClosed"
  >
    <ModalHeader
      :title="language.SHEIN_KEY_PC_28540"
      :count-down-time-stamp="promotionItem.is_count_down == 1 ? promotionItem.end_time : 0"
      @close="handleClose"
    />
    <div class="cart-promotion__tab-alert-wrapper">
      <GearTab
        :model-value="activeIndex"
        :tabs="tabs"
        :type-id="typeId"
        :fulfill-status="fulfillStatus"
        :promotion-id="promotionId"
        :language="language"
        @change="switchTab"
      />
      <AlertTips
        :text="promotionAlertTips"
        :promotion-type-id="typeId"
        :show-add-btn="notSatisfied"
        :promotion-id="promotionId"
        :level="activedItem.index"
      />
    </div>
    <ul
      v-show="!firstLoad"
      ref="productList"
      v-infinite-scroll="handleInfiniteScroll"
      class="promotion-product__list"
      infinite-scroll-disabled="disabledScroll"
      infinite-scroll-nodata="false"
      infinite-scroll-distance="10"
    >
      <PromotionProductItem
        v-for="(item, index) in activedItem.list"
        :key="'promotion-list-item-' + index + '-' + item.goods_id"
        :item="item"
        :promotion-item="promotionItem"
        :is-disabled="notSatisfied"
        :fulfill-status="fulfillStatus"
        :index="index"
        :disabled-tip="promotionAlertTips"
        :tab-name="activedItem.tabName"
        :page="activedItem.page"
      />
    </ul>
    <Skeleton v-show="firstLoad" />
  </s-dialog>
</template>

<script>
import { mapState, useStore } from 'vuex'
import ModalHeader from './components/Header.vue'
import GearTab from './components/GearTab.vue'
import AlertTips from './components/AlertTips.vue'
import PromotionProductItem from './components/PromotionProductItem.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { useAppendageHandle } from './components/appendageHandle.js'
import { getGiftProductList } from '../../utils/fetcher'
import Skeleton from './components/Skeleton.vue'

export default {
  name: 'FullGiftModal',

  // 组件注册
  components: {
    ModalHeader,
    GearTab,
    AlertTips,
    PromotionProductItem,
    Skeleton
  },
  setup() {
    let store = useStore()
    const { 
      isShow,
      promotionId,
      setPromotionId,
      promotionItem,
      typeId,
      isLoaded,
      currentIndex,
      difference,
      handleClose
    } = useAppendageHandle(store, 'fullGiftShow')
    return {
      isLoaded,
      promotionItem,
      typeId,
      isShow,
      promotionId,
      currentIndex,
      setPromotionId,
      difference,
      handleClose
    }
  },

  data () {
    return {
      activeIndex: 0,
      listArray: [],
      firstLoad: true
    }
  },
  // 计算属性
  computed: {
    ...mapState([
      'language',
      'appendageDrawer'
    ]),
    tabs(){
      return this.listArray.map(item=>{
        return {
          id: item.index,
          tabName: item.tabName,
          satisfiedStatus: this.currentReachedIndex < item.index ? false : true
        }
      })
    },
    activedItem(){
      return this.listArray.find(item=>item.index == this.activeIndex) || {}
    },
    promotionAlertTips(){
      if(this.notSatisfied) {
        return this.difference(this.activedItem.index, this.language.SHEIN_KEY_PC_28561)
      }
      return this.language.SHEIN_KEY_PC_28563
    },
    currentReachedIndex() {
      let reached = -1
      const { promotion_range_info, content } = this.promotionItem
      let range = promotion_range_info.range
      if (content.action == 2) { // 满足满赠条件
        reached = range - 1
      }
      return reached
    },
    fulfillStatus() {
      if ((this.currentReachedIndex + 1) == this.listArray.length) {
        return 1
      } else if (this.promotionItem.content.action == 2) {
        return 2
      } else {
        return 0
      }
    },
    disabledScroll() {
      return this.activedItem.complete ? true : false
    },
    notSatisfied(){
      return this.activedItem.index > this.currentReachedIndex
    }
  },
  watch: {
    isShow(n) {
      if (n) {
        this.handleShow()
      }
    }
  },
  // 方法实现
  methods: {
    switchTab (id) {
      this.activeIndex = id
      this.$refs.productList.scrollTop = 0
    },
    handleShow () {
      if(this.promotionId != this.appendageDrawer.promotionId){
        this.setPromotionId()
      }
      this.setDefaultData()
      this.init()
      // 默认展示最高档
      this.$nextTick(() => {
        if(this.appendageDrawer.fromType == 'addItem-auto'){
          this.$message({
            message: this.language.SHEIN_KEY_PC_28557,
            type: 'info'
          })
        }
        this.activeIndex = this.currentIndex
        if(this.listArray.length == 1){
          daEventCenter.triggerNotice({
            daId: '1-8-9-1',
            extraData: {
              promotion_typeid: this.typeId,
              promotion_code: this.promotionId,
              is_satisfied: this.notSatisfied ? 0 : 1,
              promotion_state: this.fulfillStatus,
              level: 1
            }
          })
        }
      })
    },
    /**
     * 设置默认属性：tabs & listArray
     */
    setDefaultData() {
      let lists = []

      function createDefaultList({ index }) {
        return {
          total: 0,
          page: 1,
          list: [],
          loading: false,   // 是否正则请求
          index,
          complete: false,  // 是否全部加载完毕
        }
      }

      const { rules } = this.promotionItem
      rules.forEach((item, index) => {
        let listItem = createDefaultList({ index })
        let tabName = `${item.value_amount?.amountWithSymbol}+`

        listItem.list = []
        listItem.tabName = tabName
        listItem.total = 0
        listItem.delivery_id = item.delivery_id
        lists.push(listItem)
      })
      this.listArray = lists
    },
    /**
     * 初始化
     */
    async init () {
      let promiseList = []
      this.tabs.forEach((item) => {
        promiseList.push(this.reFetch({ tabIndex: item.id }))
      })
      await Promise.all(promiseList)
      this.firstLoad = false
    },
    /**
     * 发送请求
     * @param {Number} tabIndex 请求的列表index
     * @param {Boolean} loadMore 加载更多
     */
    async reFetch({ tabIndex = 0, loadMore = false } = {}) {
      const target = this.listArray[tabIndex]
      if (!target || target.loading) return

      if (loadMore) {
        if (target.complete) return
        target.page++
      }

      target.loading = true

      const handleListReady = list => {
        target.loading = false
        if (!list) return
        if (loadMore) {
          target.list = target.list.concat(list)
        } else {
          target.list = list
        }
      }

      const list = await this.fetchData({ tabIndex, page: target.page, delivery_id: target.delivery_id }).catch(() => {
        target.loading = false
      })

      handleListReady(list)
    },
    /**
     * 列表请求
     */
    async fetchData({ tabIndex = 0, limit = 20, page = 1, delivery_id }) {
      const requestData = {
        promotion_id: this.promotionId,
        range: tabIndex + 1,
        limit,
        page,
        ...(delivery_id ? { delivery_id } : {})
      }
      try {
        let res = await getGiftProductList(requestData)

        if (res.code == 0 && res.info && res.info.list) {
          const { list, total } = res.info
          // 达到最后一页
          const target = this.listArray[tabIndex]
          if (list.length < 20) {
            target.complete = true
          }
          target.total = total || 0
          return Promise.resolve(list)
        } else {
          return Promise.reject(res)
        }
      } catch (e) {
        return Promise.reject(e)
      }
    },

    /**
     * 滚动到底
     */
    handleInfiniteScroll() {
      this.reFetch({ loadMore: true, tabIndex: this.activeIndex })
    },
    handleClosed() {
      this.reset()
    },
    reset() {
      this.listArray = []
      this.firstLoad = true
    }
  },
}
</script>

<style lang='less' scoped>
.cart-promotion__drawer{
  :deep(.sui-dialog__title-holder){
    padding: 0;
  }
  :deep(.sui-dialog__body){
    display: flex;
    flex-direction: column;
    padding: 0;
    min-height: 50vh;
    overflow: hidden;
  }
  .cart-promotion__tab-alert-wrapper{
    flex-shrink: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    height: 44px;
    align-items: center;
    border-bottom: 1px solid #F1F1F1;
    padding: 0 48px;
  }
  .promotion-product__list{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    padding: 0 28px 0 48px;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
